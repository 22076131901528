import { useRef, useEffect, useContext } from "react";

import classes from "./LoginForm.module.scss";
import usernameIcon from "../assets/fa-user.svg";
import passwordIcon from "../assets/carbon_password.svg";
import ValidUserContext from "../authCheck";
import bottom_side_logo from "../assets/TSG_Logo.png"

let isInitial = true;

function LoginForm() {
  const validUserContext = useContext(ValidUserContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    if (isInitial) {
      validUserContext.localAuthCheck();
      isInitial = false;
    }
  }, [validUserContext]);

  const submitHandler = (event) => {
    event.preventDefault();

    validUserContext.apiAuthCheck(
      emailInputRef.current.value,
      passwordInputRef.current.value
    );
  };

  const handleForgotClick = () => {
    validUserContext.forgotPassword()
  }

  return (
    <div className={classes.logincontainer}>
      <video 
        autoPlay 
        loop 
        muted 
        className={classes.backgroundVideo}
      >
        <source src="https://assets-global.website-files.com/66104229a70f45865267f10d/66104e2a891c600a62741b5f_war%20rooms-transcode.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <form onSubmit={submitHandler} className={classes.form}>
        <div className={classes.loginInstructions}>
        </div>
        <div>
          <input
            className={classes.input}
            type="email"
            id="user-name"
            name="user-name"
            autoComplete="on"
            placeholder="E-mail"
            ref={emailInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>

        <div>
          <input
            className={classes.input}
            type="password"
            id="user-password"
            name="user-password"
            autoComplete="off"
            placeholder="Password"
            ref={passwordInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>
        <div className={classes.forgot} onClick={() => handleForgotClick()}>Forgot Password?</div>
        <button
          className={classes.loginBtn}
          disabled={validUserContext.isLoggedIn}
        >
          {validUserContext.isLoggedIn ? "Already logged in" : "Login"}
        </button>
      </form>
      <a href="https://TSGCO.com"><img src={bottom_side_logo} alt="Your Image" className={classes.bottomLeftImage} /></a>
    </div>
    
  );
}

export default LoginForm;
