import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import "react-pro-sidebar/dist/css/styles.css"; // Import default styles
import "./Sidebar.scss"; // Import custom SCSS styles

import classes from "./Landing.module.scss";
import {useRef, useEffect, useState, useContext, useCallback, useLayoutEffect} from "react";
import ValidUserContext from "../authCheck";
import signoutIcon from "../assets/fa-logout.svg";
import menuIcon from "../assets/fa-menu.svg";
import homeIcon from "../assets/fa-home.svg";
import downloadIcon from "../assets/fa-database.svg";
import emailIcon from "../assets/fa-email.svg";
import infoIcon from "../assets/fa-info.svg";


import pdfIcon from "../assets/akar-icons_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import excelIcon from "../assets/akar-icons_excel.svg";

import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 
import sg_side_logo from "../assets/sg_side_logo.svg";
import { USMap } from 'react-us-map'
import OptionsModal from './OptionsModal'; // Adjust the path as necessary
import transparent_logo from "../assets/Warroom_Blue.png"

//import USAMap from "react-usa-map";


import Modal, {closeStyle} from 'simple-react-modal'
//import 'bootstrap/dist/css/bootstrap.css';

//import Dropdown from 'react-bootstrap/Dropdown';


import Navigation from "./Navigation";

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function folderSorter(a, b) {
  return (a[1].name > b[1].name) ? 1 : -1;
}

const Landing = () => {

    const navigationData = JSON.parse(localStorage.getItem("navigation"));

    const rootFolders = Object.entries(navigationData).filter(
        ([folderId, folderData]) => folderData.parent_id === 'ad518168-fda4-4d92-8434-d266aaa661d5'
    );

    const [currentState, setCurrentState] = useState("");

    const [currentFolder, setCurrentFolder] = useState(rootFolders);
    const [defaultGroup, setDefaultGroup] = useState(JSON.parse(localStorage.getItem("group")) ?? "default");

    const [activeTab, setActiveTab] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    const [activeButton, setActiveButton] = useState(0);
    const [activeTopIndex, setActiveTopIndex] = useState(0);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [activeDashboard, setActiveDashboard] = useState(false);
    const [activeURL, setActiveURL] = useState('');
    const [displayTabs, setDisplayTabs] = useState(false);
    const [displayToolbarButtons, setDisplayToolbarButtons] = useState(false);

    const [defultFolder, setDefaultFolder] = useState('Home');
    const [defultFolderId, setDefaultFolderId] = useState('Home');
    const [defaultUser, setDefaultUser] = useState(localStorage.getItem("login-name") ?? "");

    //const [breadCrumbs, setBreadCrumbs] = useState([{"id":buttons[0], "name":buttons[0]}]);
    const [currentButtons, setCurrentButtons] = useState([]);
    const [currentSubButtons, setCurrentSubButtons] = useState([]);

    //const [currentLinks, setCurrentLinks] = useState(links);
    const [show, setShow] = useState(false);

    //modal stuff
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const [rectPosition, setRectPosition] = useState({ top: 0, left: 0 });
    const [mapRendered, setMapRendered] = useState(false);

    //const [dashboardRef, setDashboardRef] = useState(undefined)
    const dashboardRef = useRef(null)

    const validUserContext = useContext(ValidUserContext);

    useEffect(() => {
      const handleResize = () => {
        setRectPosition({
          top: -100,
          left: -100,
        });
        const timeoutId = setTimeout(() => {
          updateBoxPosition()
        }, 500);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial position update
      updateBoxPosition();

      const timeoutId = setTimeout(() => {
        updateBoxPosition()
      }, 500);


      // Clean up the resize event listener on unmount
      return () => {
        window.removeEventListener('resize', handleResize);
        clearTimeout(timeoutId);
      };

  
    }, []);

    const updateBoxPosition = () => {
      const rectElement = document.querySelector('.d3-state-offshore-district-of-columbia');
      if (rectElement) {
        const rect = rectElement.getBoundingClientRect();
        setRectPosition({
          top: rect.top + rect.height + 3,
          left: rect.left + rect.width / 2 - 9,
        });
      }
    };

    const handleDashboardClick = (link, dashboardid) => {
        setActiveDashboard(true)
        setActiveURL(link)
        setActiveTabIndex(dashboardid);
        setDisplayTabs(false)
        setDisplayToolbarButtons(true)
    }

    const handleFolderClick = (name, id) => {
      setDefaultFolder(name)
      setDefaultFolderId(id)
      setActiveTopIndex(id)
      //breadCrumbs.push({"id":id, "name":name})
      //setBreadCrumbs(breadCrumbs)
    }

    const handleDownloadClick = () => {
      window.open("https://amazonsales.typeform.com/to/LbHaX0fc", '_blank');
    }

    const handleEmailClick = () => {
      const email = "warroom@strategygroup.com"; // Replace with the recipient's email address
      const subject = "Customer Request"; // Replace with your desired subject
      const body = ""; // Replace with your desired email body
    
      // Construct the mailto link
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }
  
    const handleExportPDFClick = () => {
      dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-pdf");
    }

    const handleExportCSVClick = () => {
      dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-csv");
    }

    const handleExportPNGClick = () => {
      dashboardRef.current.firstChild.childNodes[1].exportImageAsync();
    }

    const handleExportPWRPClick = () => {
      dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-powerpoint");
    }

    const handleCrossTabClick = () => {
      dashboardRef.current.firstChild.childNodes[1].displayDialogAsync("export-cross-tab");
    }


    const handleLogoClick = () => {
      setActiveDashboard(false)
    }

    const handleButtonClick = (topIndex, tabIndex) => {
      validUserContext.localAuthCheck();
      if(tabIndex != activeTabIndex) {
        setActiveURL(navigationData[navigationData[tabIndex].dashboards[0]].link)
      } 
      setActiveTopIndex(topIndex);
      setActiveTabIndex(tabIndex);

      //setActiveURL(currentSubButtons[topIndex][tabIndex])
      //dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-cross-tab");

      setActiveDashboard(true)
      setDefaultFolder('Home')
      setDisplayTabs(false)
      setDisplayToolbarButtons(true)
      // let newBreadCrumbs
      // // Remove elements after the specific element
      // newBreadCrumbs = [{"id":tabText, "name":tabText}]
      // setBreadCrumbs(newBreadCrumbs)
    };

    // const handleBreadCrumbClick = (folderName) => {
    //   setActiveDashboard(false)
    //   setDefaultFolder(folderName.name)
    //   setDefaultFolderId(folderName.id)

    //   let indexToRemove = breadCrumbs.indexOf(folderName);
    //   let newBreadCrumbs
    //   // Remove elements after the specific element
    //   if (indexToRemove !== -1) {
    //     newBreadCrumbs = breadCrumbs.slice(0, indexToRemove + 1);
    //   }
    //   setBreadCrumbs(newBreadCrumbs)
    // }

    const handleHomeClick  = () => {
      setDefaultFolder('Home')
      setActiveDashboard(false)
      setDisplayToolbarButtons(false)
      setMenuOpen(false)
    };

    const handleMenuClick  = () => {
      if (menuOpen) {
        setMenuOpen(false)
      } else {
        setMenuOpen(true)
      }
    };

    const handleStateHover = (event) => {
      const stateAbbreviation = event;
      setCurrentState(stateAbbreviation)
    };

    const handleStateClick = (event) => {

      var stateObject = Object.values(navigationData).find((item) => item.name === currentState)
      if (stateObject && stateObject.hasOwnProperty('children')) {
        var dashboardElements = []
        stateObject.children.forEach((element) => {
          dashboardElements.push(navigationData[element])
        });
        setOptions(dashboardElements)
        setModalPosition({ x: event.clientX, y: event.clientY });
        setModalOpen(true);
      }
      
      //setOptions()
      console.log(`You clicked on ${currentState}`);
    };

    const handleNationalHover = () => {
      setCurrentState("US National")
    }


    const handleNationalClick = (event) => {
      setCurrentState("US National")
    }

    const handleOpenModal = (event) => {
      setModalPosition({ x: event.clientX, y: event.clientY });


      setModalOpen(true);
    };
  
    const handleCloseModal = (option) => {
      if (option.hasOwnProperty('dashboards')){
        //setSelectedOption(option);
        setActiveURL(navigationData[option.dashboards[0]].link)

        setActiveTopIndex(option.parent_id);
        setActiveTabIndex(navigationData[option.dashboards[0]].parent_id);

        setActiveDashboard(true)
        setDefaultFolder('Home')
        setDisplayTabs(false)
        setDisplayToolbarButtons(true)
      }
      setModalOpen(false);
    };


    const renderContent = () => {
      if (activeDashboard)
          return renderDashboard()
      else 
          //return renderNavigation()
          return renderMap()
    } 

    const renderNavigation = () => {
      return (
        <div>
            <Navigation defaultFolder={defultFolder} defaultFolderId={defultFolderId} onDashboardClick={handleDashboardClick} onFolderClick={handleFolderClick}></Navigation>
        </div>
      )
    }

    async function getSelectedMarks(event) {
      const filterSelected = await event.detail.getFilterAsync();
      if (filterSelected.fieldName == "Tableau Group" && filterSelected.appliedValues.length>0) {
        const valueSelected = filterSelected.appliedValues[0].value;          
        console.log(`${valueSelected}  Selected`);
        setDefaultGroup(valueSelected)
      } else if (filterSelected.fieldName == "Tableau Group"){
        setDefaultGroup("default")
      }
    }

    const renderMap = () => {
      // Check if both activeURL and displayTabs are not null
      // fill: black;
      // stroke: #29a4c3;
      return (
          <div className={classes.mapblock} onClick={handleStateClick}>
              <USMap 
                  fill={d => 'black'}
                  stroke={d => '#31bbdf'}
                  strokeWidth={d => '3'}
                  fillHover={d => '#31bbdf'}
                  // textFill={d => currentState == d ? 'white' : '#31bbdf'}
                  textFill={d => currentState == d ? 'white' : '#31bbdf'}
                  textFillHover={d => 'black'}
                  tooltipHTML={d => handleStateHover(d)}
              />
              <div className={classes.floatingBoxContainer}         style={{
                  top: `${rectPosition.top}px`,
                  left: `${rectPosition.left}px`,
                }}>
                <div className={classes.floatingBox} onMouseEnter={handleNationalHover} onClick={handleStateClick}>
                </div>
                <div className={classes.floatingBoxText}>
                  NATIONAL
                </div>
              </div>
          </div>
      );
      // return (
      //   <USAMap onClick={handleStateClick} />
      // );
    };

    const renderDashboard = () => {
      // Check if both activeURL and displayTabs are not null
      if (activeURL && displayTabs != null) {
        return (
            <Dashboard defaultGroup={defaultGroup} dashboardLinkProp={activeURL} displayTabs={displayTabs} filterChanged={(event) => getSelectedMarks(event)}/>
        );
      }
    
      // Optionally, return null or some fallback content if the parameters are null
      return null;
    };
    
    
    const rendeToolbar = () => {  
      
    }
    
    
    const renderButtons = () => {  
      return currentButtons.map((buttonText, index) => {
        if (activeTopIndex === index) {
            return (<div className={`${classes.sideButton} ${classes.active}`} onClick={() => handleButtonClick(index, 0, buttonText)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        } else {
            return (<div className={`${classes.sideButton}`} onClick={() => handleButtonClick(index, 0, buttonText)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        }
      });
    };

    const renderDrowpdowns = () => {  
      var sortedFolder = currentFolder.sort(folderSorter)

      return (
        <ProSidebar>
          <Menu iconShape="square">
          {sortedFolder.map(([topFolderId, folderData]) => (
              <SubMenu className={`${classes.sideButtonTop} ${topFolderId === activeTopIndex ? classes.active : ''}`} title={navigationData[topFolderId].name.toUpperCase()} defaultOpen={topFolderId === activeTopIndex}>
              {navigationData[topFolderId].children.map((folderId) => (
                  <MenuItem className={`${classes.sideButtonTab} ${folderId === activeTabIndex ? classes.active : ''}`} onClick={() => handleButtonClick(topFolderId, folderId)}>{navigationData[folderId].name.toUpperCase()}</MenuItem>
              ))}   
              </SubMenu>
          ))}        
          </Menu>
        </ProSidebar>
      )
    };


    // const renderDrowpdowns = () => {  
    //   return (
    //     <ProSidebar>
    //       <Menu iconShape="square">
    //         <SubMenu className={`${classes.sideButtonTop} ${classes.active}`} title="Dashboard Hub">
    //           <MenuItem className={`${classes.active}`}>Component 1</MenuItem>
    //           <MenuItem>Component 2</MenuItem>
    //         </SubMenu>
    //         <SubMenu className={`${classes.sideButtonTop}`} title="Custom">
    //           <MenuItem>View 1</MenuItem>
    //           <MenuItem>View 2</MenuItem>
    //         </SubMenu>          
    //       </Menu>
    //     </ProSidebar>
    //   )
    // };
    const handleShow = () => {
      setShow(true)
    };
   
    const handleClose = () => {
      setShow(false)
    };


    var companyLink = 'https://storage.googleapis.com/gf_portal_artifacts/logos/'+defaultGroup.toLowerCase()+'.png'
    var defaultLink = 'https://storage.googleapis.com/gf_portal_artifacts/logos/default.png'
    const handleError = (event) => {
      event.target.src = defaultLink;
    };

    const renderFull = () => {
      return (
          <div className={`${classes.landing}`}>

          <div className={`${menuOpen ? classes.sidebar : classes.sidebarClosed}`}>
            <div className={`${classes.sidebarclose}`}>
              <button className={`${classes.sidebarclosebutton}`} onClick={handleMenuClick}>X</button> 
            </div>
            {renderDrowpdowns()}
            {/* <div className={`${classes.sidebarlogoCircle}`}>
              <img className={classes.sidebarlogo} src={companyLink} onError={handleError}></img>
            </div> */}

          

            {/* <div className={`${classes.sidePortalName}`}>
              <img className={classes.sidebarlogo} src={pv_transparent_logo}></img>
            </div> */}
          </div>
          <div className={`${classes.contentblockMobile}`}>
            <div className={`${activeDashboard ? classes.toolbarBlack : classes.toolbar}`}>
                {/* <img
                      className={classes.icon}
                      src={signoutIcon}
                      alt="Signout icon"
                      htmlFor="sign-out"
                      onClick={() => handleLogoutClick()}
                ></img>
                <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>Logout</span> */}
                  <div className={`${classes.toolbarleft}`}> 
                      <img
                        className={`${classes.menuicon}`}
                        src={menuIcon}
                        alt="Menu icon"
                        htmlFor="menu-click"
                        onClick={() => handleMenuClick()}
                      ></img>
                      <img
                        className={`${classes.homeicon}`}
                        src={homeIcon}
                        alt="Menu icon"
                        htmlFor="menu-click"
                        onClick={() => handleHomeClick()}
                      ></img>
                      {/* <img
                      className={classes.titleLogo}
                      src={pv_transparent_logo}
                      alt="PDF icon"
                      htmlFor="download-pdf"
                      onClick={() => handleDownloadClick()}
                      ></img> */}
                  </div>
                  <div className={`${classes.toolbarcenter}`}> 
                      <a href="/"><img className={classes.landingLogo} src={transparent_logo}></img></a>

                      {/* <title className={classes.loginTitle}>{actionText}</title> */}
                  </div>
                  <div className={`${classes.toolbarright}`}>
                      <img
                      className={classes.icon}
                      src={signoutIcon}
                      alt="Signout icon"
                      htmlFor="sign-out"
                      onClick={() => handleLogoutClick()}
                      ></img>
                      {displayToolbarButtons? 
                      (
                      <div>
                        <img
                        className={classes.dividericon}
                        src={dividerIcon}
                        alt="Divider icon"
                        htmlFor="divider"
                        ></img>
                        <img
                        className={classes.pdficon}
                        src={pdfIcon}
                        alt="PDF icon"
                        htmlFor="download-pdf"
                        onClick={() => handleExportPDFClick()}
                        ></img>
                        <img
                        className={classes.pdficon}
                        src={pngIcon}
                        alt="PNG icon"
                        htmlFor="download-png"
                        onClick={() => handleExportPNGClick()}
                        ></img>
                        <img
                        className={classes.pwrpicon}
                        src={pwrpIcon}
                        alt="Power Point icon"
                        htmlFor="download-pwrp"
                        onClick={() => handleExportPWRPClick()}
                        ></img>
                        <img
                        className={classes.excelicon}
                        src={excelIcon}
                        alt="Excel icon"
                        htmlFor="download-excel"
                        onClick={() => handleCrossTabClick()}
                        ></img>
                      </div>
                      ):(
                        <div></div>
                      )}
                  </div>

                {/* {breadCrumbs.map((folderName) => (
                  <div className={`${classes.breadcrumb}`}>{folderName.name} &gt;</div>
                ))} */}
            </div>
            <div className={classes.dashboardblock} ref={dashboardRef} >
                {renderContent()}
            </div>
            {selectedOption && <p>Selected Option: {selectedOption}</p>}
              <OptionsModal
                isOpen={modalOpen}
                onClose={handleCloseModal}
                options={options}
                title={currentState}
                position={modalPosition}
              />
          </div>
          
        </div>
      )
    }  

    // var jwtToken = JSON.parse(localStorage.getItem("tableau-login-data"));
    // localStorage.setItem("tableau-login-data", JSON.stringify("redeemed"));

    // var inputProps = {
    // };
    
    // if (jwtToken != "redeemed") {
    //   inputProps.token = jwtToken;
    // }
    var dashboardURL = JSON.parse(localStorage.getItem("dashboard-url"));
    dashboardURL = dashboardURL + "ACA-Travel/TravelMediaPerformance" + '?:showVizHome=no&:embed=true&:toolbar=no&:tabs=n'

    return (
      <div>
        {
          renderFull()
        }
      </div>
    );
  };
  
  export default Landing;
