import React from 'react';
import Modal from 'react-modal';
import classes from "./OptionsModal.module.scss"; // Import custom SCSS styles

const OptionsModal = ({ isOpen, onClose, options, title,  position }) => {
  const customStyles = {
    overlay: {
      zIndex: 1000, // Ensure the overlay has a high z-index
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: darken the overlay background
    },
    content: {
      top: `${position.y}px`,
      left: `${position.x}px`,
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      zIndex: 1001, // Ensure the content has a higher z-index
      borderRadius: '15px', // Curved box
      padding: '20px',
      backgroundColor: '#fff', // White background for the modal content
      color: '#000', // Black text color
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional: add a shadow for better visibility
    },
  };

  const listStyles = {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  };

  const listItemStyles = {
    padding: '0px 0',
    cursor: 'pointer',
    color: '#000', // Explicitly set black text color for list items
    listStyleType: 'none',
    fontSize: '16px',
  };

  const ulStyles = {
    margin: 0,
    padding: 0,
  }

  return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Options Modal"
      >
        <h2 style={listItemStyles}>{title.toUpperCase()}</h2>
        <ul style={ulStyles}>
          {options.map((option, index) => (
            <li className={classes.listItem} key={index} style={listItemStyles} onClick={() => onClose(option)}>
              {option.name.toUpperCase()}
            </li>
          ))}
        </ul>
      </Modal>
  );
};

export default OptionsModal;