import { useRef, useEffect, useContext } from "react";

import classes from "./LoginForm.module.scss";
import ValidUserContext from "../authCheck";
import usernameIcon from "../assets/fa-user.svg";


let isInitial = true;

function SendEmailForm() {
  const validUserContext = useContext(ValidUserContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const passwordConfirmInputRef = useRef();

  useEffect(() => {
    if (isInitial) {
      validUserContext.localAuthCheck();
      isInitial = false;
    }
  }, [validUserContext]);

  const submitHandler = (event) => {
    event.preventDefault();
    validUserContext.apiSendPwdResetHandler(
        emailInputRef.current.value
    );
  };

  return (
    <div className={classes.logincontainer}>
    <video 
      autoPlay 
      loop 
      muted 
      className={classes.backgroundVideo}
    >
      <source src="https://assets-global.website-files.com/66104229a70f45865267f10d/66104e2a891c600a62741b5f_war%20rooms-transcode.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
      <form onSubmit={submitHandler} className={classes.form}>
      <div className={classes.loginInstructions}>Please enter your email address to reset your passsword</div>
      <div>
          <input
            className={classes.input}
            type="email"
            id="user-name"
            name="user-name"
            autoComplete="on"
            placeholder="E-mail"
            ref={emailInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>
        <button
          className={classes.loginBtn}
          disabled={validUserContext.isLoggedIn}
        >
          {validUserContext.isLoggedIn ? "Already logged in" : "Send Email"}
        </button>
      </form>
    </div>
  );
}

export default SendEmailForm;
