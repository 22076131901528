import classes from "./LoginHead.module.scss";
//import transparent_logo from "../assets/sg_white_logo.svg"
import transparent_logo from "../assets/Warroom_Blue.png"

import warroom_logo from "../assets/warroom.png"

import { useContext} from "react";
import ValidUserContext from "../authCheck";

function LoginHead() {
  const validUserContext = useContext(ValidUserContext);

  const handleReset = () => {
    validUserContext.reset()
  }
  var actionText = 'WAR ROOM'
  if(validUserContext.isForgotPwd) {
    actionText = ''
  } else if (validUserContext.pwdResetTokenValue){
    actionText = ''
  } else if (validUserContext.newUserTokenValue){
    actionText = ''
  }

  return (
    <div> 
      <a href="/"><img className={classes.loginLogo} src={transparent_logo} onClick={() => handleReset()}></img></a>

      {/* <title className={classes.loginTitle}>{actionText}</title> */}
    </div>
  );
}

export default LoginHead;
